import logo from "../../assets/logo.svg";

function Logo() {
  return (
    <a href="/" className="inline-flex items-end">
      <div className="bg-green p-6 pt-16 shrink-0">
        <img className="w-16" src={logo} alt="" />
      </div>
      <span className="text-green font-modak text-xl mx-6 xs:mx-12 mb-6">
        Frokost<br />firmaet
      </span>
    </a>
  );
}

export default Logo;
