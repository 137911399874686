import { useState } from "react";
import { getParams } from "../../helper";
import LikeRange from "../LikeRange";
import { BASE_API_URL } from "../../config";

function FormRating({ children, onSuccess }) {
  const [error, setError] = useState({});

  async function submitHandler(e) {
    e.preventDefault();
    const { securityHash } = getParams(window.location.href);
    const radioButton = [...e.target["like-range"]];
    const selected = radioButton.find((button) => button.checked);

    if (!selected) {
      radioButton[2].focus();
      radioButton[2].checked = true;
      setError({ message: "Venligst vælg en rating" });
      return;
    }

    const response = await fetch(`${BASE_API_URL}/api/feedback/ratings`, {
      method: "POST",
      body: JSON.stringify({
        score: Number(selected.value)
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        SecurityHash: securityHash,
      },
    });

    onSuccess?.(true);
  }

  return (
    <form
      className="grow flex flex-col items-center justify-between max-h-96"
      onSubmit={submitHandler}
      onChange={() => setError({})}
    >
      <LikeRange error={error} />
      {children}
    </form>
  );
}

export default FormRating;
