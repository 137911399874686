import { useEffect, useState } from "react";
import Button from "./components/Button";
import FormRating from "./components/FormRating";
import Heading from "./components/Heading";
import Logo from "./components/Logo";
import { dateToWeek, getParams } from "./helper";
import { BASE_API_URL } from "./config";
import Paragraph from "./components/Paragraph";

function App() {
  const [success, setSuccess] = useState(false);
  const [date, setDate] = useState(false);
  const { securityHash } = getParams(window.location.href);
  
  useEffect(() => {
    fetch(`${BASE_API_URL}/api/feedback/rating_invitation`, {
      method: "GET",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        SecurityHash: securityHash,
      },
    })
    .then((response) => response.json())
    .then((response) => setDate(response.rating_invitation.created_at));
  }, [securityHash]);

  return (
    <div className="min-h-screen flex flex-col">
      <header className="pl-6 xs:pl-20">
        <Logo />
      </header>
      <main className="grow flex flex-col max-w-7xl w-full mx-auto px-2 xs:px-12 sm:px-20">
        {success ? <ThankYou /> : <Rating date={date} setSuccess={setSuccess} />}
      </main>
    </div>
  );
}

function Rating({date, setSuccess}) {
  return (
    <>
      <Heading>Uge {dateToWeek(date)}</Heading>
      <Paragraph className="mb-16">Bedøm ugens menu</Paragraph>
      <FormRating onSuccess={setSuccess}>
        <Button className="my-16">Bedøm</Button>
      </FormRating>
    </>
  );
}

function ThankYou() {
  return (
    <>
      <Heading>Tak for din feedback!</Heading>
      <Paragraph>Vi vil bruge den til at forbedre vores menuer.</Paragraph>
      <Paragraph>Den vil blive brugt til at følge jeres samlede tilfredshed og give mulighed for at følge udviklingen uge for uge, både for den ansvarlige for frokosten, køkkenet og Frokostfirmaet.</Paragraph>
      <Paragraph>Hvis du ønsker at give yderligere feedback, kan du gøre det direkte fra mobilappen under "feedback", som vil blive videresendt til det køkken, der har leveret maden til dig.</Paragraph>
      <Paragraph>Vi ønsker dig en fantastisk weekend!</Paragraph>
    </>
  );
}

export default App;
