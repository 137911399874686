import { IconAwesome, IconAwful, IconBad, IconGood, IconOk } from "./Icon";

function LikeRange({error}) {
  return (
    <div className="w-full relative grid grid-cols-5 justify-items-center mb-10 py-2 before:absolute before:block before:top-1/2 before:-translate-y-1/2 before:h-1.5 sm:before:h-2.5 before:w-4/5 before:bg-grayGreen">
      {error.message && <span className="absolute left-0 bottom-full mb-4 text-red text-xs">{error.message}</span>}
      <Input id="1" value="1" color="hover:before:bg-red peer-checked:before:bg-red" label={<>Rædsels&shy;fuldt!</>} icon={<IconAwful />} />
      <Input id="2" value="2" color="hover:before:bg-orange peer-checked:before:bg-orange" label="Dårligt" icon={<IconBad />} />
      <Input id="3" value="3" color="hover:before:bg-yellow peer-checked:before:bg-yellow" label="OK" icon={<IconOk />} />
      <Input id="4" value="4" color="hover:before:bg-yellowGreen peer-checked:before:bg-yellowGreen" label="Godt" icon={<IconGood />} />
      <Input id="5" value="5" color="hover:before:bg-clearGreen peer-checked:before:bg-clearGreen" label={<>Fan&shy;tastisk!</>} icon={<IconAwesome />} />
    </div>
  )
}

function Input({id, value, color, label, icon}) {
  return (
    <div className="relative rounded-full">
      <input
        className="sr-only peer"
        id={"like-range-" + id}
        type="radio"
        name="like-range"
        value={value}
      />
      <label
        className={color + " peer-focus-visible:ring-1 peer-focus-visible:ring-black relative block w-12 h-12 sm:w-16 sm:h-16 rounded-full bg-grayGreen cursor-pointer hover:before:absolute hover:before:rounded-full hover:before:inset-1.5 peer-checked:before:inset-1.5 sm:hover:before:inset-2.5 sm:peer-checked:before:inset-2.5 peer-checked:before:absolute peer-checked:before:rounded-full"}
        htmlFor={"like-range-" + id}
      >
        <span className="absolute pt-2 sm:pt-5 text-sm sm:text-md top-full text-center sm:whitespace-nowrap left-1/2 -translate-x-1/2">
          {label}
        </span>
      </label>
      {icon}
    </div>
  )
}

export default LikeRange;
