export const IconAwful = () => (
  <svg className="absolute hidden top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-4 pointer-events-none sm:w-6 peer-checked:block" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M23 15C23 15 20.25 10 12 10C3.75 10 1 15 1 15" stroke="white" strokeWidth="2" strokeLinecap="round"/>
    <path d="M1 1H9" stroke="white" strokeWidth="2" strokeLinecap="round"/>
    <path d="M15 1L23 1" stroke="white" strokeWidth="2" strokeLinecap="round"/>
    <circle cx="5" cy="3" r="3" fill="white"/>
    <circle cx="19" cy="3" r="3" fill="white"/>
  </svg>
)

export const IconBad = () => (
  <svg className="absolute hidden top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-4 pointer-events-none sm:w-6 peer-checked:block" width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M23 15C23 15 20.25 10 12 10C3.75 10 1 15 1 15" stroke="white" strokeWidth="2" strokeLinecap="round"/>
    <circle cx="5" cy="3" r="3" fill="white"/>
    <circle cx="19" cy="3" r="3" fill="white"/>
  </svg>
)

export const IconOk = () => (
  <svg className="absolute hidden top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-4 pointer-events-none sm:w-6 peer-checked:block" viewBox="0 0 24 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 12H23" stroke="white" strokeWidth="2" strokeLinecap="round"/>
    <circle cx="5" cy="3" r="3" fill="white"/>
    <circle cx="19" cy="3" r="3" fill="white"/>
  </svg>
)

export const IconGood = () => (
  <svg className="absolute hidden top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-4 pointer-events-none sm:w-6 peer-checked:block" viewBox="0 0 24 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 11C1 11 3.75 16 12 16C20.25 16 23 11 23 11" stroke="white" strokeWidth="2" strokeLinecap="round"/>
    <circle cx="5" cy="3" r="3" fill="white"/>
    <circle cx="19" cy="3" r="3" fill="white"/>
  </svg>
)

export const IconAwesome = () => (
  <svg className="absolute hidden top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-4 pointer-events-none sm:w-6 peer-checked:block" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M24 10.0001C24 16.6275 18.6274 22.0001 12 22.0001C5.37258 22.0001 0 16.6275 0 10.0001H24Z" fill="white"/>
    <circle cx="5" cy="3" r="3" fill="white"/>
    <circle cx="19" cy="3" r="3" fill="white"/>
  </svg>
)