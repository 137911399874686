export function getParams(pathName) {
  return {
    securityHash: pathName.split('/').slice(-1)
  };
}

export function dateToWeek(date) {
  const tmpDate = new Date(date);
  const currentDate = new Date(tmpDate.valueOf());
  const dayn = (tmpDate.getDay() + 6) % 7;
  currentDate.setDate(currentDate.getDate() - dayn + 3);
  const firstThursday = currentDate.valueOf();
  currentDate.setMonth(0, 1);
  if (currentDate.getDay() !== 4) {
    currentDate.setMonth(0, 1 + ((4 - currentDate.getDay()) + 7) % 7);
  }
  return 1 + Math.ceil((firstThursday - currentDate) / 604800000);
}
